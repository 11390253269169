/* You can add global styles to this file, and also import other style files */
@use '/src/assets/css-vars.scss' as *;

:root {
    --mat-toolbar-title-text-font: "Inter", sans-serif;
}

html,
body {
    height: 100%;
    background-color: slategray;
}

h3,
h4 {
    font-family: "Inter", sans-serif;
}

p {
    text-align: left;
}

body {
    max-width: 800px;
    margin: 0 auto;
    font-family: "Inter", "Calibri", "Helvetica Neue", sans-serif;
}

section,
header {
    padding: .5rem;
    margin: 0px;
    max-width: 1000px;
    background-color: $bg2;
}

section {
    border-radius: 1rem;
}

article {
    background-color: inherit
}

app-root {
    display: inline;
    text-align: center;
}


* {
    font-family: "Inter", "Calibri", "Helvetica Neue", sans-serif;
}

.symbol {
    display: inline-flex;
    min-height: .8rem;
    max-height: 1.2rem;
    flex-direction: row;
    justify-content: center;
    padding: 0px 5px;

}

.symbol.lg {
    max-height: 1.5rem;
    height: 1.5rem;
}

.symbol.sm {
    min-height: .5rem;
    max-height: 1.2rem;
}

.invert {
    filter: invert(100%);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    // background-color: $input;
    border: 1px solid grey;
    border-radius: 4px;
}

ins.adsbygoogle {
    margin-top: 2rem;
    overflow-anchor: none;
}

.flex-row,
.flex-left {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: .5rem;
}

.flex-left {
    flex-direction: row;
    justify-content: flex-start;

}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: .5rem;


    mat-form-field {
        flex: 1;
        min-width: 200px;
        margin: 0 .5rem 0 0;
    }



}

.narrow {
    max-width: 60%;
    margin: 0 auto;

}

textarea,
input,
select {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: .25rem 0rem;
    font-size: 1rem;

}

textarea {
    min-height: 300px;
}

button {
    background-color: $green;
    border-radius: 4px;
    cursor: pointer;
    min-height: 2rem;
    font-size: medium;
    margin: .5rem .25rem .5rem 0rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid $green;
}

button:hover,
button:focus {
    color: black;
    background-color: $green;
    border: 1px solid black;
    
}

button:disabled {
    background-color: #eee;
    color: #aaa;
    cursor: auto;
}

button.withImage {
    display: inline-block;
    min-width: 140px;
    line-height: 2rem;
    text-align: center;
    vertical-align: middle;

    img {
        vertical-align: sub;
    }
}

details, .rounded-box {
    padding: .5rem;
    text-align: left;
    border-radius: 1rem;

}

details summary {
    cursor: pointer;
    text-align: left;
}

summary {
    margin-bottom: .5rem;
    margin-top: .5rem;
}


:lang(ar) {
    direction: rtl;
}

// .withBorder {
//     border: thin solid black;
//     padding: .5rem;
// }

.withShadow {
    box-shadow: 6px 5px 5px 1px slategrey
}

.withMarginBottom {
    margin-bottom: 1rem;
}

.withMarginTop {
    margin-top: 1rem;
}

.withMarginLeft {
    margin-left: 1rem;
}

.bg1 {
    background-color: $bg;
}

.bg2 {
    background-color: $bg2;
}

.settings {
    background-color: $green;
}

.center {
    text-align: center;
}

.withPaddingBottom {
    padding-bottom: 1rem;
}


.container {
    display: flex;
    justify-content: center;
    padding: 0rem .5rem;
    height: auto;
    margin: 1rem 0rem;
    // border: 10px solid $primary;
}

.banner-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 1rem;
    
}

.box {
    background-color: inherit;
    font-size: inherit;
    color: inherit;
    border-radius: 1rem;
}

.spaced {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

button.grey {
    background-color: rgba(0, 0, 0, 0.14);
    margin: 0 .5rem;
    border: 1px solid rgba(0, 0, 0, 0.14);
}

button.grey:disabled {
    display: none;
}

button.grey:hover {
    border: 1px solid black;
}

.smaller {
    font-size: smaller;
}

.lessons-table {
    padding: 1rem 0rem;
    border-radius: 1rem;
    background-color: $primary;
    color: $green;
    overflow-x: auto;

    sub {
        font-size: medium;
        color: $green;
        
        a{
            text-decoration: none;
            cursor: pointer;
            color: $green;
        }
    }
}